import { EventType, pushEvent } from "./index";

/**
 * Login
 */

export const event7 = () => {
  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.signIns,
      eventLabel: "Login",
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    },
    subcategory: EventType.read
  });
};
