import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import "./styles.scss";
import { Link } from "../Link";
import {
  SanityCtaLabelsInterface,
  SanityErrorMessagesLabelsInterface,
  SanityFormsLabelsInterface
} from "../../types/SanityTypes";
import Button from "../Button";
import Field from "../Forms/Field";
import { checkUserAuthenticated, userLogin } from "../../services/salesforce/Auth";
import EcommerceContext from "../../services/EcommerceContextService";
import LoaderIcon from "../../images/icons/loader";
import { mergeBasket } from "../../services/salesforce/Bag";
import Section from "../Section";
import { event7 } from "../../analytics/event7";

export type LoginFormInterface = {
  signUp?: {
    _id: string;
    slug: {
      current: string;
    };
  };
  checkoutStatus?: {
    _id: string;
    slug: {
      current: string;
    };
  };
  forgottenPassword: {
    _id: string;
    banner: {
      heading: string;
    };
    slug: {
      current: string;
    };
  };
  isCheckout?: boolean;
} & SanityCtaLabelsInterface &
  SanityErrorMessagesLabelsInterface &
  SanityFormsLabelsInterface;

export const LoginForm: FunctionComponent<LoginFormInterface> = ({
  forgottenPassword,
  signUp,
  checkoutStatus,
  formsLabels,
  errorMessages,
  isCheckout
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const { isUserAuthenticated, setUserAuthenticated } = React.useContext(EcommerceContext);

  useEffect(() => {
    isUserAuthenticated && window.location.pathname.match(`/login`) && navigate("/profile");
  }, []);

  const formFields: any = [
    {
      type: "email",
      name: "email",
      label: formsLabels?.email,
      placeholder: "helloworld@gmail.com",
      rules: {
        required: true,
        pattern:
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      },
      message: errorMessages?.validEmail
    },
    {
      type: "password",
      name: "password",
      label: formsLabels?.password,
      rules: {
        required: true,
        pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,}$"
      },
      message: errorMessages?.incorrectPassword || "Please, enter correct password"
    }
  ];

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const handleFormSubmit = async (data: any) => {
    const { email, password } = data;
    try {
      setIsLoading(true);
      setError(null);
      const previousUserAuthenticated = checkUserAuthenticated();
      const response = await userLogin(email, password);
      if (!previousUserAuthenticated) {
        await mergeBasket();
      }
      if (checkUserAuthenticated()) {
        event7();
        setUserAuthenticated(true);
        navigate(isCheckout ? "/bag" : "/profile");
      }
      if (response.response.status === 401) {
        setError(response.response.data.message);
      } else {
        setError(null);
      }
    } catch (err) {
      console.log("error..", err);
      // if (err?.response?.data) setError(err?.response?.data);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Section sectionClassName={"c-loginForm"}>
      <div className="c-loginForm__wrapper">
        <form onSubmit={handleSubmit(handleFormSubmit)} className="">
          {formFields.map((field: any, index: number) =>
            Array.isArray(field) ? (
              <div className="grid-row" key={index}>
                <div className="grid-col-12">
                  {field.map(field => (
                    <Field
                      className="input-text"
                      key={field.name}
                      {...field}
                      registerInput={register}
                      errors={errors}
                      message={field.message}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <Field
                className="input-text"
                key={field.name}
                {...field}
                registerInput={register}
                errors={errors}
                message={field.message}
              />
            )
          )}
          <div className="forgot-password">
            <Link _id={forgottenPassword?._id} to={forgottenPassword?.slug.current}>
              Forgot Password?
            </Link>
          </div>
          <p className="error-msg">{error}</p>
          <Button className="w-100 btn-login smt-16" type="submit" variant="nonary" form="br-50" disabled={isLoading}>
            Login
            {isLoading && <LoaderIcon width={13} height={13} className="c-loginForm__loaderIcon" />}
          </Button>
          {signUp && signUp.slug && (
            <div className="sign-up-block text-center smt-36">
              <span>
                Don’t have an account?&nbsp;
                <Link _id={signUp?._id} to={signUp?.slug.current as string}>
                  Sign up!
                </Link>
              </span>
            </div>
          )}
          {checkoutStatus && checkoutStatus.slug && (
            <div className="check-order-block text-center smt-16">
              <span>
                Already made an order?&nbsp;
                <Link _id={checkoutStatus?._id} to={checkoutStatus?.slug.current as string}>
                  Check status here!
                </Link>
              </span>
            </div>
          )}
        </form>
      </div>
    </Section>
  );
};

export default LoginForm;
