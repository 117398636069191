import * as React from "react";
import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { graphql, PageProps, withPrefix } from "gatsby";
import { LocalisedPageContext } from "../../types/PageTypes";
import SEO from "../../components/Seo";
import {
  ImageInterface,
  InternalLink,
  LocalizedContextInterface,
  SanityColorList,
  SanitySiteSettings
} from "../../types/SanityTypes";
import LoginForm from "../../components/LoginForm";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import Helmet from "react-helmet";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import HeroBanner from "../../components/HeroBanner";

export const query = graphql`
  query Login($_id: String, $language: String) {
    login: sanityLogin(_id: { eq: $_id }) {
      _id
      banner {
        heading
        subHeading
        primaryColor {
          value
          title
        }
        contentAlignment
        isVideoBanner
        _rawHeroImage(resolveReferences: { maxDepth: 10 })
        heroImage {
          alt
          ...Image
        }
        _rawHeroVideo(resolveReferences: { maxDepth: 10 })
      }
      slug {
        current
      }
    }
    sanitySiteSettings(i18n_lang: { eq: $language }) {
      name
      description
    }
    sanityForgottenPassword(i18n_lang: { eq: $language }, pageType: { eq: "reset" }) {
      _id
      banner {
        heading
      }
      slug {
        current
      }
    }
    sanitySignUp(i18n_lang: { eq: $language }) {
      _id
      slug {
        current
      }
    }
    sanityCheckOrderStatus(i18n_lang: { eq: $language }) {
      _id
      slug {
        current
      }
    }
    ...LocalizedContext
  }
`;

type LoginQueryProps = {
  sanitySiteSettings: SanitySiteSettings;
  login: {
    _id: string;
    banner: {
      heading: string;
      subHeading?: string;
      contentAlignment: string;
      _rawHeroImage: ImageInterface;
      heroImage: ImageInterface;
      _rawHeroVideo: any;
      primaryColor: SanityColorList;
      isVideoBanner: boolean;
    };
    slug: {
      current: string;
    };
  };
  sanityForgottenPassword: InternalLink & {
    banner: {
      heading: string;
    };
  };
  sanitySignUp: InternalLink;
  sanityCheckOrderStatus: InternalLink;
} & LocalizedContextInterface;

type LoginPageContext = LocalisedPageContext;

const Login = (props: PageProps<LoginQueryProps, LoginPageContext>) => {
  const { siteUrl } = useSiteMetadata();
  const site = props.data.sanitySiteSettings;
  const login = props.data.login;
  const localizedPath = withPrefix(getLocalizedPath(login.slug.current, login._id));
  const [isCheckout, setIsCheckout] = useState(false);

  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(window.location.search));
    params.checkout && setIsCheckout(true);
  }, []);

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={login.banner?.heading}
      pageType={"signIn"}
    >
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <SEO title={login.banner?.heading} description={site?.description} />

      <div className="page_login">
        <HeroBanner
          image={login.banner?._rawHeroImage}
          sanityImage={login.banner?.heroImage}
          title={login.banner?.heading}
          alignment={login.banner?.contentAlignment}
          isVideoBanner={login.banner?.isVideoBanner}
          video={login.banner?._rawHeroVideo}
        />
        <LoginForm
          isCheckout={isCheckout}
          login={login}
          profile={props.data.sanityProfile}
          forgottenPassword={props.data.sanityForgottenPassword}
          signUp={props.data.sanitySignUp}
          ctaLabels={props.data.sanityLabels?.ctaLabels}
          formsLabels={props.data.sanityLabels?.formsLabels}
          errorMessages={props.data.sanityLabels?.errorMessages}
          checkoutStatus={props.data.sanityCheckOrderStatus}
        />
      </div>
    </Layout>
  );
};

export default Login;
